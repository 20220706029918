import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";

// Import our SiteArea component
import SiteArea from "src/components/SiteArea";
import HuduDataDialog from "src/features/HuduDataDialog";

// Import API methods we'll use to fetch data from the backend
import { fetchSiteData } from "src/api/Api";

// Our Home component
// This will be a vertical stack of title + image + Hudu table sections
// We could add a nice header as the first item in the stack

const Home = (props) => {
  const [siteData, setSiteData] = useState([]);
  const [selectedHuduPipe, setSelectedHuduPipe] = useState({});

  const [huduDetailOpen, setHuduDetailOpen] = useState(false);

  // Function to fetch site data from the API and put it into
  // our component state
  const updateSiteData = async () => {
    const newSiteData = await fetchSiteData(props.siteId);

    setSiteData(newSiteData);
  };

  // useEffect will be called when our Home component renders
  // This is where we can make an API call to fetch the site data
  // useEffect is a built-in React "hook" which React will
  // call for us during the render process

  useEffect(() => {
    // Call our async function to update site data from the API
    // We need to have this as an outside function because it's
    // asynchronous. useEffect doesn't like async
    updateSiteData();
  }, []);

  const closeHuduDetailDialog = () => {
    setHuduDetailOpen(false);
  };

  const displayHuduDetails = (pipe) => {
    setSelectedHuduPipe(pipe);
    setHuduDetailOpen(true);
  };

  // Render our component
  // We'll use a Box component to force the width to 100%. We don't
  // specify a height. The content will expand vertically and the app
  // will allow scrolling vertically to see more content.

  // Inside our Box, we'll have a vertical Stack of SiteAreas
  // We'll use the standard map function to iterate through
  // the site area list to render each area

  // ----------------
  //      Area A
  //      Area B
  //      Area C
  // ----------------

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2}>
        {siteData.map((area) => (
          <SiteArea area={area} handleHuduPipeClick={displayHuduDetails} />
        ))}
      </Stack>
      <HuduDataDialog
        open={huduDetailOpen}
        handleClose={closeHuduDetailDialog}
        pipe={selectedHuduPipe}
      />
    </Box>
  );
};

// Export the component so other components can import it
export default Home;
