import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

const HuduTable = (props) => {
  return (
    <TableContainer component={Paper} elevation={2}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Pipe ID</TableCell>
            <TableCell align="left" sx={{ fontWeight: "bold", displayPrint: "none" }}>
              License-Line
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: "bold" }}>
              Status Actual
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: "bold" }}>
              From Location
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: "bold" }} >
              From Facility
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: "bold" }}>
              To Location
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: "bold" }}>
              To Facility
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.pipes.map((pipe) => (
            <TableRow
              key={pipe.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Button
                  variant="contained"
                  onClick={() => props.handleHuduPipeClick(pipe)}
                >
                  {pipe.client_pl_id === null ? pipe.id : pipe.client_pl_id}
                </Button>
              </TableCell>
              <TableCell align="left" sx={{ displayPrint: "none"}}>{pipe.license_line_num}</TableCell>
              <TableCell align="left">{pipe.status_actual}</TableCell>
              <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>{pipe.from_location}</TableCell>
              <TableCell align="left">{pipe.from_facility_license}</TableCell>
              <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>{pipe.to_location}</TableCell>
              <TableCell align="left">{pipe.to_facility_license}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HuduTable;
