import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import PipeData from "src/components/PipeData";
import Box from '@mui/material/Box';

const HuduDataDialog = (props) => {
    return (
    <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullScreen
      >
      {props.pipe ? ( 
        <DialogContent>
            <PipeData
            pipe={props.pipe}
            >
            </PipeData>
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                textAlign: 'center',
                paddingTop: '20px'
              }}
            >
              <Typography sx={{ fontSize: '2rem' }}>For incorrect data, please update in HUDU</Typography>
            </Box>
        </DialogContent>) : null}
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={props.handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
};


export default HuduDataDialog;

