import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

/** The PipeData component
--------------------------------------------------------------
This returns a table of data to describe a selected pipe. Currently pulling in Pipe ID, Total Mitagated Risk Category, Material Type, Status, Substance License. 
These categories may change depending on what information Ashton or Chad would like to see.
*/

const PipeData = (props) => {
  const data = [
    { label: "Pipe ID", value: props.pipe.client_pl_id },
    { label: "HUDU ID", value: props.pipe.id },
    { label: "License-Line", value: props.pipe.license_line_num },
    { label: "Status Actual", value: props.pipe.status_license },
    { label: "Status License", value: props.pipe.status_license },
    { label: "Substance License", value: props.pipe.substance_license },
    { label: "OD License (mm)", value: props.pipe.od_license_mm },
    { label: "Material License", value: props.pipe.material_license },
    {
      label: "Internal Protection License",
      value: props.pipe.internal_protection_license,
    },
    { label: "MOP License (KPa)", value: props.pipe.mop_license_kpa },
    { label: "Length (km)", value: props.pipe.length_license_km },
    { label: "From Location", value: props.pipe.from_location },
    { label: "From Facility License", value: props.pipe.from_facility_license },
    { label: "To Location", value: props.pipe.to_location },
    { label: "To Facility License", value: props.pipe.to_facility_license },
  ];
  return (
    <TableContainer
      component={Paper}
      elevation={2}
      sx={{ width: "auto", maxWidth: "400px", margin: "auto" }}
    >
      <Table size="small">
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                {row.label}
              </TableCell>
              <TableCell align="left">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PipeData;
