import logo from "./logo.svg";
import "./App.css";
import Home from "src/features/Home";
import "./config";

// Main entry point of our application
// Since we only have one page in the app, we
// don't need anything fancy in here. Just one
// component called "Home" which will act as our
// main page.

function App() {
  console.log(window.location);

  var siteId = null;

  if (window.location.href.includes("/pages/")) {
    // Handle old style QR code reference which looks like this
    // https://qrcodes.strathconaresources.com/pages/SITE_ID/index.html
    const uriParts = window.location.href.split("/");
    siteId = uriParts[4].replace("%20", " ");
  } else {
    // Assume new style QR code reference which looks like this
    // https://qrcodes.strathconaresources.com/?siteId=SITE_ID
    const search = window.location.search;
    const params = new URLSearchParams(search);
    siteId = params.get("siteId").replace("%20", " ");
  }

  return (
    <div className="App">
      <Home siteId={siteId} />
    </div>
  );
}

export default App;
