import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HuduTable from "src/components/HuduTable";

// The SiteArea component
// This returns a caption for the area, the associated image and (potentially)
// a table with Hudu data. Not every area will have a Hudu data table.

// -----------------
//      Caption
//       Image
//   HUDU Data Table

const SiteArea = (props) => {
  return (
     <Box
      display="flex"
      flexDirection="column"
      width="100%"
      className="page-break"
    >
      <Typography>{props.area.caption}</Typography>
      <img src={props.area.imageURL} alt={props.area.caption} />

      {props.area.huduPipes.length > 0 ? (
        <HuduTable
          pipes={props.area.huduPipes}
          handleHuduPipeClick={props.handleHuduPipeClick}
        ></HuduTable>
      ) : null}
    </Box>
  );
};

export default SiteArea;
