// Get all data about a site by passing the siteId
export async function fetchSiteData(siteId) {
  let api_url = new URL(global.config.api.url.concat(`/site/${siteId}`));

  const siteDataResponse = await fetch(api_url, {
    method: "GET",
    headers: {
      "x-api-key": global.config.api.apiKey,
      Accept: "application/json",
    },
  });

  const siteData = await siteDataResponse.json();

  return siteData;
}
